<template>
  <div :class="breadcrumbClass">
    <ul v-if="display" class="container mx-auto flex flex-initial text-white">
      <li
        v-for="(item, i) in breadcrumbs"
        :key="item.route"
        class="item-list flex flex-initial font-bbva font-medium text-15 text-blue-500"
      >
        <router-link v-if="!isLast(i)" :to="item.route" data-test="breadcrumbs-links">
          {{ item.text }}
        </router-link>
        <span v-else class="actual-item-breadcrumb font-bbva-book font-normal text-blue-450">{{
          item.text
        }}</span>
        <span v-if="!isLast(i)" class="separator">&gt;</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { viewerRoute, NavigationRoutes } from '@/shared/constants/navigation.routes'

export default {
  name: 'AppBreadcrumbs',
  computed: {
    ...mapState('portal', ['pages', 'homeId', 'secondaryPageId']),

    display() {
      return !!(this.$route.params.id != this.homeId && this.breadcrumbs.length)
    },

    breadcrumbs() {
      const { name: viewerName } = NavigationRoutes.general.viewer

      const homeBreadCrumb = {
        route: `${viewerRoute}${this.homeId}`,
        text: `${this.$t('HOME.BREADCRUMB')}`,
      }

      if (!this.$route.name) {
        return [homeBreadCrumb]
      }

      if (this.$route.name !== viewerName) {
        const translationKey = this.$route.name
          .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
          .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1_$2')
          .toUpperCase()

        return [
          homeBreadCrumb,
          {
            route: this.$route.fullPath,
            text: this.$t(translationKey + '.BREADCRUMB'),
          },
        ]
      }

      const breadcrumbs = []
      let page = this.pages[this.$route.params.id]

      if (!page) {
        return breadcrumbs
      }

      do {
        if ([this.secondaryPageId, this.homeId].includes(page.id)) {
          breadcrumbs.push(homeBreadCrumb)
          break
        }

        breadcrumbs.push({
          route: `${viewerRoute}${page.id}`,
          text: page.menuText || page.title,
        })
      } while ((page = this.pages[page.parentId]))

      breadcrumbs.reverse()

      return breadcrumbs
    },

    breadcrumbClass() {
      const id = this.$route.params.id
      const isLanding = this.pages[id] && this.pages[id].component === 'Landing'

      return {
        'app-breadcrumbs': true,
        'app-breadcrumbs-blue': isLanding,
        'internal-padding': true,
      }
    },
  },
  methods: {
    isLast(i) {
      return i === this.breadcrumbs.length - 1
    },
  },
}
</script>

<style lang="scss">
.app-breadcrumbs {
  &-blue {
    background-color: theme('colors.blue.450');

    .item-list {
      color: theme('colors.white');
    }

    .actual-item-breadcrumb {
      color: theme('colors.white');
    }
  }

  li {
    height: 72px;
    line-height: 72px;
  }

  .separator {
    margin: 0 24px;
  }
}
</style>
