<template>
  <nav v-if="!isDc" class="app-menu bg-blue-700 internal-padding">
    <ul class="container mx-auto flex items-center">
      <li v-for="(id, text) in links" :key="text" class="text-15">
        <router-link :to="'/viewer/' + id" class="text-white" data-test="menu-link">{{
          text
        }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AppMenu',
  computed: {
    ...mapState('portal', ['homeId', 'mainMenu']),
    ...mapGetters('auth', ['isDc']),

    links() {
      return {
        [this.$t('HOME.MENU')]: this.homeId,
        ...this.mainMenu,
      }
    },
  },
}
</script>

<style lang="scss">
.app-menu {
  ul {
    height: 72px;
  }

  li {
    margin-right: 44px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
