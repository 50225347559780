<template>
  <div class="app-input-text flex bg-grey-100 border-b border-grey-500 rounded-1" :class="classes">
    <input
      v-if="type !== 'money' && type !== 'textarea'"
      :type="type"
      :name="name"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :disabled="disabled"
      class="flex-1"
      data-test="input-text"
      @keydown="checkKey"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
    />

    <money
      v-if="type === 'money'"
      v-bind="money"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :disabled="disabled"
      class="flex-1"
      @input="$event !== undefined && $emit('input', $event)"
    />

    <textarea
      v-if="type === 'textarea'"
      :name="name"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :disabled="disabled"
      class="flex-1 h-32"
      data-test="input-text"
      @keydown="checkKey"
      @input="$emit('input', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
import { Money } from 'v-money'
import { money as moneyFormat } from '@/shared/constants/formats'

const exclude = /Backspace|Enter|Tab|Delete|Del|ArrowUp|Up|ArrowDown|Down|ArrowLeft|Left|ArrowRight|Right|Home|Shift|Alt/

export default {
  name: 'AppInput',

  components: {
    Money,
  },

  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    name: String,
    maxlength: [Number],
    value: [String, Number],
    placeholder: String,
    icon: String,
    chars: RegExp,
    error: Boolean,
    money: {
      type: Object,
      default: () => moneyFormat,
    },
  },

  computed: {
    classes() {
      const classes = []

      if (this.error) {
        classes.push('bg-red-100')
      }

      return classes
    },
  },

  methods: {
    checkKey($event) {
      if (this.chars && !exclude.test($event.key) && !this.chars.test($event.key)) {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss">
.app-input-text {
  padding: 12px 16px;

  input,
  textarea {
    padding: 0;
    border: 0;
    margin: 0;
    background-color: transparent;
    outline: 0;
  }

  input {
    height: 24px;
    line-height: 24px;
  }

  textarea {
    height: 96px;
  }
}
</style>
