import Base from './Base'

export default class Landing extends Base {
  cards = []
  component = 'Landing'

  constructor(data) {
    super(data, ['subtitle', 'text', 'landing'])
  }
}
