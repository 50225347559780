export function maskPhone(phone) {
  if (!phone) return
  return '*** ** ' + phone.slice(-4, -2) + ' ' + phone.slice(-2)
}

export function maskEmail(email) {
  if (!email) return

  const [account, domain] = email.split('@')

  return account.slice(-4).padStart(account.length, '*') + '@' + domain
}
