export default class User {
  constructor(id, data) {
    this.id = id
    this.plate = data.matricula
    this.validationSae = data.claveSAE_verificacion
    this.validationPhone = data.telefono_verificacion

    if (/^0034/.test(this.validationPhone)) {
      this.validationPhone = this.validationPhone.substring(4)
    }

    this.validationEmail = data.email_verificacion
    this.isSenior = data.is_senior
    this.isDc = data.is_dc
  }
}
