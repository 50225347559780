import Base from './Base'
import Image from '../Image'

export default class TitleSubtitleExternalLink extends Base {
  component = 'TitleSubtitleExternalLink'

  constructor(data) {
    super(data, ['subtitle', 'linkText', 'linkUrl'])
    this.image = data.image ? new Image(data.image) : undefined
  }
}
