<template>
  <header class="app-header bg-blue-800 internal-padding">
    <div class="container mx-auto bg-blue-800 flex justify-between items-end py-32">
      <router-link class="flex items-end" :to="homeUrl">
        <img class="h-32" src="/imgs/logo-bbva-white.svg" alt="Logo BBVA" />
      </router-link>
      <app-header-links v-if="$route.meta.showHeaderLinks !== false" />
    </div>
  </header>
</template>

<script>
import HomeUrl from '@/mixins/HomeUrl'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderLinks: () => import('./AppHeaderLinks'),
  },
  mixins: [HomeUrl],
}
</script>

<style lang="scss">
.app-header {
  .container {
    height: 135px;
  }
}
</style>
