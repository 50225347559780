import personalDataCMSAssembler from '@/views/personal-data/helpers/personalDataCMSAssembler'
import contactDataAssembler from '@/views/personal-data/helpers/contactDataAssembler'
import withHoldingDataAssembler from '@/views/personal-data/helpers/withHoldingDataAssembler'
import pensionDataAssembler from '@/views/personal-data/helpers/pensionDataAssembler'
import FamilyDataCMSAssembler from '@/views/personal-data/helpers/familyDataCMSAssembler'

export default {
  setNations(state, payload) {
    state.nations = payload
  },
  setCivilStates(state, payload) {
    state.civilStates = payload
  },
  setGenres(state, payload) {
    state.genres = payload
  },
  setHandicapExtended(state, payload) {
    state.handicapExtended = payload
  },
  setRelationships(state, payload) {
    state.relationships = payload
  },
  setResidentCategories(state, payload) {
    state.residentCategories = payload
  },
  setWorkCategory(state, payload) {
    state.workCategory = payload
  },
  setPensionRelationship(state, payload) {
    state.pensionRelationship = payload
  },
  setPersonalData(state, payload) {
    state.notAssembledPersonalData = payload
  },
  setAssembledPersonalData(state, payload) {
    state.assembledPersonalData = payload
  },
  setAssembledContactData(state, payload) {
    state.assembledContactData = payload
  },
  setAssembledFamilyData(state, payload) {
    state.assembledFamilyData = payload
  },
  setWithHoldingData(state, payload) {
    state.assembledWithHoldingsData = payload
  },
  setPersonalDataFormRawDataToCMS(state, payload) {
    state.personalDataFormDataReadyToSendToCMSAssembler = payload
  },
  setPersonalDataFormDataToCMSStructure(state, payload) {
    state.personalDataFormDataReadyToSendToCMS = personalDataCMSAssembler.assemble(
      state.personalDataFormDataReadyToSendToCMSAssembler,
      payload
    )
    state.sectionToUpdate = payload.integrationName
  },
  setContactDataFormRawDataToCMS(state, payload) {
    state.contactDataFormDataReadyToSendToCMSAssembler = payload
  },
  setContactDataFormDataToCMSStructure(state, payload) {
    state.contactDataFormDataReadyToSendToCMS = contactDataAssembler.assembleToCMS(
      state.contactDataFormDataReadyToSendToCMSAssembler,
      payload
    )

    state.sectionToUpdate = payload.integrationName
  },
  setFamilyDataFormRawDataToCMS(state, payload) {
    state.familyDataFormDataReadyToSendToCMSAssembler = payload
  },
  setFamilyDataFormDataToCMSStructure(state, payload) {
    state.familyDataFormDataReadyToSendToCMS = FamilyDataCMSAssembler.assembleToCMS(
      state.familyDataFormDataReadyToSendToCMSAssembler,
      payload
    )

    state.sectionToUpdate = payload.integrationName
  },
  setPensionDataFormRawDataToCMS(state, payload) {
    state.pensionDataFormDataReadyToSendToCMSAssembler = payload
  },
  setPensionDataFormDataToCMSStructure(state, payload) {
    state.pensionFormDataReadyToSendToCMS = pensionDataAssembler.assembleToCMS(
      state.pensionDataFormDataReadyToSendToCMSAssembler,
      payload
    )

    state.sectionToUpdate = payload.integrationName
  },
  setWithHoldingFormRawDataToCMS(state, payload) {
    state.withHoldingFormDataReadyToSendToCMSAssembler = payload
  },
  setWithHoldingDataFormDataToCMSStructure(state, payload) {
    state.withHoldingFormDataReadyToSendToCMS = withHoldingDataAssembler.assembleToCMS(
      state.withHoldingFormDataReadyToSendToCMSAssembler,
      payload
    )

    state.sectionToUpdate = payload.integrationName
  },
  setAssembledPensionData(state, payload) {
    state.assembledPensionData = payload
  },
  setStatusResponse(state, payload) {
    state.status = payload
  },
}
