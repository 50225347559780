<template>
  <ul class="app-messages container mx-auto">
    <li
      v-for="(message, index) in messages"
      :key="index"
      class="flex flex-nowrap px-24 py-12 rounded-6 bg-green-50"
    >
      <img
        :src="getSrcFromType(message.type)"
        width="64"
        height="64"
        class="flex-none mr-28"
        alt="icon"
      />
      <p class="flex-auto self-center">{{ message.text }}</p>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppMessages',

  computed: {
    ...mapState('messages', ['messages']),
  },

  methods: {
    getSrcFromType(type) {
      if (type === 'success') {
        return '/imgs/success.svg'
      }
    },
  },
}
</script>
