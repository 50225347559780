import QuestionAnswer from '../QuestionAnswer'
import Base from './Base'

export default class TitleSubtitleTextQuestionsAndAnswers extends Base {
  component = 'TitleSubtitleTextQuestionsAndAnswers'

  constructor(data) {
    super(data, ['title', 'subtitle', 'text'])
    this.faqs = data.faq ? data.faq.map((faqItem) => new QuestionAnswer(faqItem)) : undefined
  }
}
