import Image from './Image'

export default class ElementsColumn {
  constructor(data) {
    this.id = data.id
    this.icon = data.icon ? new Image(data.icon) : undefined
    this.title = data.title
    this.elements = data.elements
  }
}
