class PensionDataAssembler {
  assemble(data, relationships) {
    if (!data || !relationships) {
      return
    }

    return {
      pensionDateSpouse: this.assembleStartPensionDate(data.fechaComienzoPensionConyuge),
      amountSpouse: this.assembleAmount(data.importeConyuge),
      pensionDateChildren: this.assemblePensionChildrenDate(data.fechaComienzoPensionHijo),
      amountChildren: this.assembleAmount(data.importeHijo),
      status: data.estado,
    }
  }

  assembleAmount(amount) {
    if (amount === null) {
      amount = 0
    }

    return amount
  }

  assembleDate(date) {
    if (!date) {
      return
    }
    const splittedDate = date.split('-')
    return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2])
  }

  buildDisabledDates() {
    const date = new Date()
    const dateInMilliseconds = date.getTime()
    const MILLISECONDS_IN_A_DAY = 86400000

    return new Date(dateInMilliseconds + MILLISECONDS_IN_A_DAY)
  }

  assembleStartPensionDate(startPension) {
    return {
      date: this.assembleDate(startPension),
      disabledDates: this.buildDisabledDates(),
    }
  }

  assemblePensionChildrenDate(startPensionChildren) {
    return {
      date: this.assembleDate(startPensionChildren),
      disabledDates: this.buildDisabledDates(),
    }
  }

  assembleToCMS(data, fileInformation) {
    if (!data || !fileInformation) {
      return
    }

    return {
      fechaComienzoPensionConyuge: this.setDateToCMS(data.pensionDateSpouse),
      importeConyuge: data.amountSpouse,
      fechaComienzoPensionHijo: this.setDateToCMS(data.pensionDateChildren),
      importeHijo: data.amountChildren,
      nombreFichero: fileInformation.files.name,
      fichero: fileInformation.files.base64.toString(),
    }
  }

  setDateToCMS(pensionDate) {
    if (!pensionDate) {
      return null
    }

    const year = pensionDate.getFullYear()
    const month = pensionDate.getMonth() + 1
    const day = pensionDate.getDate()

    return `${year}-${month}-${day}`
  }
}

export default new PensionDataAssembler()
