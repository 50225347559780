export default {
  setUid(state, uid) {
    state.uid = uid
  },

  setToken(state, token) {
    state.token = token
  },

  setUser(state, user) {
    state.user = user
  },

  setDoubleFactor(state, isActivated) {
    state.isDoubleFactorActived = isActivated
  },

  setRetired(state, isRetired) {
    state.isRetired = isRetired
  },

  setWidow(state, isWidow) {
    state.isWidow = isWidow
  },

  setName(state, name) {
    state.name = name
  },

  setForbidden(state, isForbidden) {
    state.isForbidden = isForbidden
  },
}
