import ElementsColumn from './ElementsColumn'

export default class ElementsColumsCard {
  constructor(title, columns) {
    this.title = title
    this.columns = columns ? columns.map((column) => new ElementsColumn(column)) : undefined
  }

  get hasColumns() {
    return !!this.columns && this.columns.length > 0
  }
}
