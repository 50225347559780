class PersonalDataAssembler {
  assemble(data, nationalities, civilStates, genres, handicapExtended, residenceTypes) {
    if (
      !data ||
      !nationalities ||
      !civilStates ||
      !genres ||
      !handicapExtended ||
      !residenceTypes
    ) {
      return
    }
    return {
      name: data.nombre,
      firstSurname: data.primerApellido,
      secondSurname: data.segundoApellido,
      genre: this.assembleGenre(data.genero, genres),
      civilState: this.civilSateOptionBuilder(data.estadoCivil, civilStates),
      civilStateOptions: this.civilSateOptionsBuilder(civilStates),
      civilStateDate: this.assembleCivilStateDate(data.fechaEstadoCivil),
      idCard: this.assembleIdCard(data.dni),
      birthDate: this.assembleBirthDate(data.fechaNacimiento),
      nationality: this.nationalityOptionBuilder(data.nacionalidad, nationalities),
      nationalityOptions: this.nationalitiesOptionsBuilder(nationalities),
      fiscalResidence: this.fiscalResidenceOptionBuilder(data.residente, residenceTypes),
      fiscalResidenceOptions: this.fiscalResidencesOptionsBuilder(residenceTypes),
      handicap: this.assembleHandicap(data.minusvalia),
      handicapExtended: this.assembleHandicapExtended(data.catego_minusvalia, handicapExtended),
      socialSecurity: data.nss,
      status: data.estado,
    }
  }

  assembleSurnames(data) {
    return data.apellidos ? data.apellidos : `${data.primerApellido} ${data.segundoApellido}`
  }

  assembleGenre(option, genres) {
    if (option === null) {
      option === '2'
    } else if (!option || !genres) {
      return
    }

    const maleInformation = this.getMaleInformation(option, genres)
    const femaleInformation = this.getFemaleInformation(option, genres)
    const isMale = option === '1'

    return [
      {
        name: maleInformation.literalLargo,
        value: maleInformation.codigo,
        model: isMale,
      },
      {
        name: femaleInformation.literalLargo,
        value: femaleInformation.codigo,
        model: !isMale,
      },
    ]
  }

  getMaleInformation(genre, genres) {
    return genres.find((genreOption) => genreOption.codigo === '1')
  }

  getFemaleInformation(genre, genres) {
    return genres.find((genreOption) => genreOption.codigo === '2')
  }

  assembleHandicapExtended(option, handicapExtended) {
    if (!handicapExtended) {
      return
    }

    const hasHandicapExtented = this.getHasHandicapExtended(option, handicapExtended)
    const notHandicapExtented = this.getNotHandicapExtended(option, handicapExtended)

    const notHandicap = option === 'N' || option === null

    return [
      {
        name: hasHandicapExtented.literalLargo,
        value: hasHandicapExtented.codigo,
        model: !notHandicap,
      },
      {
        name: notHandicapExtented.literalLargo,
        value: notHandicapExtented.codigo,
        model: notHandicap,
      },
    ]
  }

  getHasHandicapExtended(option, handicapExtended) {
    return handicapExtended.find((handicapExtendedOption) => handicapExtendedOption.codigo === 'S')
  }

  getNotHandicapExtended(option, handicapExtended) {
    return handicapExtended.find((handicapExtendedOption) => handicapExtendedOption.codigo === 'N')
  }

  nationalityOptionBuilder(option, nationalities) {
    if (!option || !nationalities) {
      return
    }

    const preSelectedNationalityRaw = this.nationalityFinder(option, nationalities)

    return {
      value: preSelectedNationalityRaw.codigo,
      title: preSelectedNationalityRaw.literalLargo,
    }
  }

  nationalityFinder(nationality, nationalities) {
    return nationalities.find((nation) => nation.codigo === nationality)
  }

  nationalitiesOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return {
        value: option.codigo,
        title: option.literalLargo,
      }
    })
  }

  civilSateOptionBuilder(option, civilStates) {
    if (!option || !civilStates) {
      return
    }

    const preSelectedCivilStateRaw = this.civilStateFinder(option, civilStates)
    return {
      value: preSelectedCivilStateRaw.codigo,
      title: preSelectedCivilStateRaw.literalLargo,
    }
  }

  civilStateFinder(civilState, civilStates) {
    return civilStates.find((civilStateOption) => civilStateOption.codigo === civilState)
  }

  civilSateOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return { value: option.codigo, title: option.literalLargo }
    })
  }

  assembleIdCard(idCard) {
    if (!idCard) {
      return
    }

    return (idCard = idCard.includes('-') ? idCard.replace('-', '') : idCard)
  }

  assembleDate(date) {
    if (!date) {
      return
    }
    const splittedDate = date.split('-')

    return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2])
  }

  buildDisabledDates() {
    const date = new Date()
    const dateInMillisencons = date.getTime()
    const MILLISECONDS_IN_A_DAY = 86400000

    return new Date(dateInMillisencons + MILLISECONDS_IN_A_DAY)
  }

  assembleCivilStateDate(civilStateDate) {
    return {
      date: this.assembleDate(civilStateDate),
      disabledDates: this.buildDisabledDates(),
    }
  }

  assembleBirthDate(birthDate) {
    return {
      date: this.assembleDate(birthDate),
      disabledDates: this.buildDisabledDates(),
    }
  }

  fiscalResidenceOptionBuilder(fiscalResidence, residenceTypes) {
    if (fiscalResidence === null) fiscalResidence = 'S'
    if (fiscalResidence !== 'S') fiscalResidence = 'N'
    if (!fiscalResidence || !residenceTypes) return

    const preselectedResidenceRaw = this.fiscalResidenceFinder(fiscalResidence, residenceTypes)

    return {
      title: preselectedResidenceRaw.literalLargo,
      value: preselectedResidenceRaw.codigo,
    }
  }

  fiscalResidenceFinder(fiscalResidence, residenceTypes) {
    return residenceTypes.find((residenceOption) => residenceOption.codigo === fiscalResidence)
  }

  fiscalResidencesOptionsBuilder(residenceTypes) {
    if (!residenceTypes) {
      return
    }

    const residentInformation = this.getResidentInformation(residenceTypes)
    const notResidentInformation = this.getNotResidentInformation(residenceTypes)

    return [
      {
        title: residentInformation.literalLargo,
        value: residentInformation.codigo,
      },
      {
        title: notResidentInformation.literalLargo,
        value: notResidentInformation.codigo,
      },
    ]
  }

  getResidentInformation(residenceTypes) {
    return residenceTypes.find((residenceOption) => residenceOption.codigo === 'S')
  }

  getNotResidentInformation(residenceTypes) {
    return residenceTypes.find((residenceOption) => residenceOption.codigo === 'N')
  }

  assembleHandicap(handicap) {
    if (handicap === undefined) {
      return
    }
    const hasHandicap = !!handicap
    return {
      values: [
        {
          name: 'Si',
          value: 'Y',
          model: hasHandicap,
        },
        {
          name: 'No',
          value: 'N',
          model: !hasHandicap,
        },
      ],
      extended: {
        percentage: handicap,
      },
    }
  }
}

export default new PersonalDataAssembler()
