import Base from './Base'

export default class TitleSubtitleFreeHtml extends Base {
  component = 'TitleSubtitleFreeHtml'

  constructor(data) {
    super(data, ['subtitle'])
    this.text = data.free_text
  }
}
