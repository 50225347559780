import http from '../http'
import store from '@/store'

export default {
  prefix: '/employees/',

  async get(url, params = {}) {
    params.uid = store.state.auth.uid || ''

    const response = await http.get(this.prefix + url, {
      params,
    })

    return response.data
  },

  async post(url, data = {}) {
    const response = await http.post(this.prefix + url, data, {
      params: {
        uid: store.state.auth.uid,
      },
    })

    return response.data
  },

  async put(url, data = {}) {
    const response = await http.put(this.prefix + url, data, {
      params: {
        uid: store.state.auth.uid,
      },
    })

    return response.data
  },
}
