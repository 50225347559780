import Base from './Base'
import DropdownOption from '../DropdownOption'

export default class Dropdown extends Base {
  component = 'Dropdown'

  constructor(data) {
    super(data)
    this.subtitle = data.card_text
    this.questionTitle = data.subtitle
    this.dropdownDescription = data.dropdownDescription
    this.options = data.options.map((option) => new DropdownOption(option))
  }
}
