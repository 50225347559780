import Vue from 'vue'
import axios from 'axios'
import { dcBaseURL, seniorBaseURL } from '@/shared/constants/api.routes'
import { isDcUrl } from '@/shared/utils/isDcUrl'

const instance = axios.create({
  baseURL: isDcUrl() ? dcBaseURL : seniorBaseURL,
})

Vue.prototype.$http = instance

export default instance
