import { viewerRoute } from '@/shared/constants/navigation.routes'
import Models from '@/shared/models'
import cms from '@/shared/services/cmsService'

export default {
  async loadAuth({ commit, dispatch }) {
    try {
      const type = 'authentication.AuthenticationPage'

      commit('setRequestInProgressStatus', true)
      const id = (await cms.getPagesIdByType(type))[0]
      commit('setRequestInProgressStatus', false)

      if (!id) {
        return
      }

      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPage', id)
      commit('setRequestInProgressStatus', false)

      commit('setAuthId', id)
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async loadHome({ commit, dispatch, rootGetters }) {
    try {
      const type = 'principalPages.PrincipalPagesPage'

      commit('setRequestInProgressStatus', true)
      const id = (await cms.getPagesIdByType(type))[0]
      commit('setRequestInProgressStatus', false)

      if (!id) {
        return
      }

      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPage', id)
      commit('setRequestInProgressStatus', false)

      commit('setHomeId', id)

      if (rootGetters['auth/authenticated']) {
        commit('setRequestInProgressStatus', true)
        dispatch('loadMainMenuPages')
        dispatch('loadHeaderLandings')
        commit('setRequestInProgressStatus', false)
      }
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async loadMainMenuPages({ state, commit, dispatch }) {
    try {
      const landingsId = state.pages[state.homeId].children

      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPages', landingsId)
      commit('setRequestInProgressStatus', false)

      let childsId = []

      for (const id of landingsId) {
        const page = state.pages[id]
        if (page.component === 'Carousel') {
          commit('setCarouselId', id)
        } else {
          if (page.menuText) {
            commit('addToMainMenu', { id: page.id, text: page.menuText })
          }

          if (page.landing) {
            commit('setLandingId', { id: page.id, landing: page.landing })
          }
        }

        const childs = state.pages[id].children

        childsId = [...childsId, ...childs]
      }

      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPages', childsId)
      commit('setRequestInProgressStatus', false)
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async loadHeaderLandings({ state, commit, dispatch, rootGetters }) {
    try {
      const type = 'secondaryPages.SecondaryPagesPage'

      commit('setRequestInProgressStatus', true)
      const pageId = (await cms.getPagesIdByType(type))[0]
      commit('setRequestInProgressStatus', false)

      if (!pageId) {
        return
      }

      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPage', pageId)
      commit('setRequestInProgressStatus', false)

      commit('setSecondaryPageId', pageId)

      if (!rootGetters['auth/authenticated']) {
        return
      }

      const headerLandingsId = state.pages[pageId].children
      commit('setRequestInProgressStatus', true)
      await dispatch('fetchPages', headerLandingsId)
      commit('setRequestInProgressStatus', false)

      for (const id of headerLandingsId) {
        const headerPage = state.pages[id]
        commit('setLandingId', { landing: headerPage.component, id: headerPage.id })
        if (headerPage.menuText) {
          commit('addToHeaderMenu', {
            url: `${viewerRoute}${headerPage.id}`,
            text: headerPage.menuText,
          })
        }
      }
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async fetchPages({ dispatch, commit }, ids) {
    try {
      for (const id of ids) {
        await dispatch('fetchPage', id)
      }
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async fetchPage({ commit, dispatch }, id) {
    try {
      const page = await cms.getPageById(id)
      const hasModel = Models[page.meta.type]

      if (hasModel) {
        const model = new Models[page.meta.type](page)
        dispatch('checkAddPage', model)
      }
    } catch (error) {
      commit('setRequestInProgressStatus', false)
      throw new Error(error)
    }
  },

  async checkAddPage({ rootGetters, commit }, page) {
    if (page.card) {
      const urls = {
        '/special-agreement': rootGetters['auth/isSpecialAgreementAllowed'],
        '/loans': rootGetters['auth/areLoansAllowed'],
        '/autocertificados': rootGetters['auth/areAutocertificatesAllowed'],
      }
      const isAllowed = urls[page.card.url]
      if (isAllowed === false) return
    }

    commit('setPage', page)
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
