import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import messages from './modules/messages'
import personalData from './modules/personal-data'
import portal from './modules/portal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    messages,
    personalData,
    portal,
  },
})
