import employeeService from '@/shared/services/employeeService'
import personalDataAssembler from '@/views/personal-data/helpers/personalDataAssembler'
import contactDataAssembler from '@/views/personal-data/helpers/contactDataAssembler'
import pensionDataAssembler from '@/views/personal-data/helpers/pensionDataAssembler'
import withHoldingDataAssembler from '@/views/personal-data/helpers/withHoldingDataAssembler'
import familyDataAssembler from '@/views/personal-data/helpers/familyDataAssembler'

export default {
  async fetchAllPersonalData({ dispatch }) {
    await dispatch('fetchNations')
    await dispatch('fetchSocialStates')
    await dispatch('fetchGenres')
    await dispatch('fetchHandicapExtended')
    await dispatch('fetchRelationships')
    await dispatch('fetchResidentCategory')
    await dispatch('fetchWorkCategory')
    await dispatch('fetchPensionRelationship')
    await dispatch('fetchPersonalData')
    await dispatch('assembleContactData')
    await dispatch('assembleFamilyData')
    await dispatch('assemblePensionData')
    await dispatch('assembleWithHoldingData')
    dispatch('assemblePersonalData')
  },
  async fetchNations({ commit }) {
    const nations = await employeeService.getNations()

    commit('setNations', nations)
  },
  async fetchSocialStates({ commit }) {
    const civilStates = await employeeService.getCivilStates()

    commit('setCivilStates', civilStates)
  },
  async fetchGenres({ commit }) {
    const genres = await employeeService.getGenres()

    commit('setGenres', genres)
  },
  async fetchHandicapExtended({ commit }) {
    const handicapExtended = await employeeService.getHandicapExtended()

    commit('setHandicapExtended', handicapExtended)
  },
  async fetchRelationships({ commit }) {
    const relationships = await employeeService.getRelationships()
    commit('setRelationships', relationships)
  },
  async fetchPersonalData({ commit }) {
    const personalData = await employeeService.getPersonalDataForm()

    commit('setPersonalData', personalData)
  },
  async fetchResidentCategory({ commit }) {
    const residentCategories = await employeeService.getResidentCategory()

    commit('setResidentCategories', residentCategories)
  },
  async fetchWorkCategory({ commit }) {
    const workCategory = await employeeService.getWorkCategory()

    commit('setWorkCategory', workCategory)
  },
  async fetchPensionRelationship({ commit }) {
    const pensionRelationship = await employeeService.getPensionRelationship()

    commit('setPensionRelationship', pensionRelationship)
  },
  assemblePersonalData({ commit, state }) {
    const assembledPersonalData = personalDataAssembler.assemble(
      state.notAssembledPersonalData.seniorDatosS1VO,
      state.nations.listaIdentificacion,
      state.civilStates.listaIdentificacion,
      state.genres.listaIdentificacion,
      state.handicapExtended.listaIdentificacion,
      state.residentCategories.listaIdentificacion
    )

    commit('setAssembledPersonalData', assembledPersonalData)
  },
  assembleContactData({ commit, state }) {
    const assembledContactData = contactDataAssembler.assemble(
      state.notAssembledPersonalData.seniorDatosS2VO,
      state.nations.listaIdentificacion
    )

    commit('setAssembledContactData', assembledContactData)
  },
  assembleFamilyData({ commit, state }) {
    const assembledFamilyData = familyDataAssembler.assemble(
      state.notAssembledPersonalData.seniorDatosS1VO,
      state.notAssembledPersonalData.seniorDatosS3VO,
      state.genres.listaIdentificacion,
      state.civilStates.listaIdentificacion,
      state.relationships.listaIdentificacion,
      state.workCategory.listaIdentificacion
    )

    commit('setAssembledFamilyData', assembledFamilyData)
  },
  assemblePensionData({ commit, state }) {
    const assembledPensionData = pensionDataAssembler.assemble(
      state.notAssembledPersonalData.seniorDatosS4VO,
      state.pensionRelationship.listaIdentificacion
    )

    commit('setAssembledPensionData', assembledPensionData)
  },
  assembleWithHoldingData({ commit, state }) {
    const assembledWithHoldingData = withHoldingDataAssembler.assemble(
      state.notAssembledPersonalData.seniorDatosS5VO
    )

    commit('setWithHoldingData', assembledWithHoldingData)
  },
  async updatePersonalData({ commit, state }) {
    let personalDataToUpdate

    if (state.sectionToUpdate === 'seniorDatosS1VO') {
      personalDataToUpdate = {
        seniorDatosS1VO: state.personalDataFormDataReadyToSendToCMS,
      }
    } else if (state.sectionToUpdate === 'seniorDatosS2VO') {
      personalDataToUpdate = {
        seniorDatosS2VO: state.contactDataFormDataReadyToSendToCMS,
      }
    } else if (state.sectionToUpdate === 'seniorDatosS3VO') {
      personalDataToUpdate = {
        seniorDatosS3VO: state.familyDataFormDataReadyToSendToCMS,
      }
    } else if (state.sectionToUpdate === 'seniorDatosS4VO') {
      personalDataToUpdate = {
        seniorDatosS4VO: state.pensionFormDataReadyToSendToCMS,
      }
    } else if (state.sectionToUpdate === 'seniorDatosS5VO') {
      personalDataToUpdate = {
        seniorDatosS5VO: state.withHoldingFormDataReadyToSendToCMS,
      }
    }

    const response = await employeeService.savePersonalData(personalDataToUpdate)
    const status = response

    commit('setStatusResponse', status)
  },
}
