import Base from './Base'
import ContactCard from '../ContactCard'

export default class TitleSubtitleTextCards extends Base {
  component = 'TitleSubtitleTextCards'

  constructor(data) {
    super(data, ['subtitle', 'text'])
    this.cards = data.Column.map((card) => new ContactCard(card))
  }
}
