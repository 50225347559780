export const viewerRoute = '/viewer/'

export const NavigationRoutes = {
  general: {
    default: {
      path: '/',
      name: 'default',
    },
    viewer: {
      path: viewerRoute + ':id',
      name: 'viewer',
    },
    auth: {
      path: '/auth',
      name: 'auth',
    },
    myProfile: {
      path: '/my-profile',
      name: 'myProfile',
    },
    personalData: {
      path: '/personal-data',
      name: 'personalData',
    },
    unemploymentBenefits: {
      path: '/unemployment-benefits',
      name: 'unemploymentBenefits',
    },
    documentaryManager: {
      path: '/documentary-manager',
      name: 'documentaryManager',
    },
    specialAgreement: {
      path: '/special-agreement',
      name: 'specialAgreement',
    },
    autocertificados: {
      path: '/autocertificados',
      name: 'autocertificados',
    },
    loans: {
      path: '/loans',
      name: 'loans',
    },
  },
  errors: {
    unauthorized: {
      path: '/errors/unauthorized',
      name: 'unauthorized',
    },
    notFound: {
      path: '/errors/not-found',
      name: 'notFound',
    },
    serverDown: {
      path: '/errors/error',
      name: 'serverDown',
    },
    maintenance: {
      path: '/errors/maintenance',
      name: 'maintenance',
    },
  },
}
