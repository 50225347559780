import Image from './Image'
import { viewerRoute } from '@/shared/constants/navigation.routes.js'

export default class Card {
  constructor(data) {
    this.title = data.card_title
    this.text = data.card_text
    this.image = data.card_image ? new Image(data.card_image) : undefined
    this.soon = data.card_soon
    this.icon = data.card_icon ? new Image(data.card_icon) : undefined
    this.url = data.external_url_card || viewerRoute + data.id
  }
}
