import Auth from './templates/Auth'
import Carousel from './templates/Carousel'
import Dropdown from './templates/Dropdown'
import Landing from './templates/Landing'
import Principal from './templates/Principal'
import Secondary from './templates/Secondary'
import TextDocumentsColumns from './templates/TextDocumentsColumns'
import TextsDocuments from './templates/TextsDocuments'
import TitleColumnsDocuments from './templates/TitleColumnsDocuments'
import TitleSubtitleExternalLink from './templates/TitleSubtitleExternalLink'
import TitleSubtitleFreeHtml from './templates/TitleSubtitleFreeHtml'
import TitleSubtitleMultipleTexts from './templates/TitleSubtitleMultipleTexts'
import TitleSubtitleTextCards from './templates/TitleSubtitleTextCards'
import TitleSubtitleTextLink from './templates/TitleSubtitleTextLink'
import TitleSubtitleTextQuestionsAndAnswers from './templates/TitleSubtitleTextQuestionsAndAnswers'
import TitleTextDocuments from './templates/TitleTextDocuments'
import TitleText from './templates/TitleText'

export default {
  'authentication.AuthenticationPage': Auth,
  'carousel.CarouselPage': Carousel,
  'dropdown.DropdownPage': Dropdown,
  'landings.LandingPage': Landing,
  'principalPages.PrincipalPagesPage': Principal,
  'secondaryPages.SecondaryPagesPage': Secondary,
  'textDocumentsColumns.TextDocumentsColumnsPage': TextDocumentsColumns,
  'textsDocuments.TextsDocumentsPage': TextsDocuments,
  'titleColumnsDocuments.TitleColumnsDocumentsPage': TitleColumnsDocuments,
  'titleSubtitleExernalLink.TitleSubtitleExternalLinkPage': TitleSubtitleExternalLink,
  'titleSubtitleFreeHtml.TitleSubtitleFreeHTMLPage': TitleSubtitleFreeHtml,
  'titleSubtitleMultipleTexts.TitleSubtitleMultipleTextsPage': TitleSubtitleMultipleTexts,
  'titleSubtitleTextCards.TitleSubtitleTextCardsPage': TitleSubtitleTextCards,
  'titleTextDocuments.TitleTextDocumentsPage': TitleTextDocuments,
  'titleTextLink.TitleTextAndLinkPage': TitleSubtitleTextLink,
  'titleSubtitleTextAndFaq.TitleSubtitleTextAndFaqPage': TitleSubtitleTextQuestionsAndAnswers,
  'titleText.TitleTextPage': TitleText,
}
