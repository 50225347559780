// Vue
import Vue from 'vue'
// Application
import App from '@/App.vue'
// Styles
import '@/assets/styles'
// Plugins
import '@/plugins'
// Router
import router from '@/router'
// Internationalization
import i18n from '@/shared/i18n'
// Store
import store from '@/store'
// Utilities
import { isDcUrl } from '@/shared/utils/isDcUrl'

require('animate.css')

Vue.use(require('vue-moment'))

Vue.config.productionTip = false

let title, description
if (isDcUrl()) {
  title = process.env.VUE_APP_DC_TITLE
  description = process.env.VUE_APP_DC_DESCRIPTION
} else {
  title = process.env.VUE_APP_SENIOR_TITLE
  description = process.env.VUE_APP_SENIOR_DESCRIPTION
}

// set title based on user type
document.title = title

// set description meta tag based on user type
const meta = document.createElement('meta')
meta.name = 'description'
meta.content = description
document.getElementsByTagName('head')[0].appendChild(meta)

new Vue({
  router,
  store,
  i18n,
  created() {
    store.dispatch('auth/setInterceptors')
    store.dispatch('auth/checkAuthenticated')
  },
  render: (h) => h(App),
}).$mount('#app')
