import store from '@/store'

export const mustLogin = (toRoute) => {
  if (process.env.VUE_APP_AUTHENTICATE === 'false') {
    return false
  }

  if (toRoute.matched.some((route) => route.meta && route.meta.excludeAuth)) {
    return false
  }

  return !store.getters['auth/authenticated']
}

export const mustDoubleFactor = (toRoute) => {
  if (process.env.VUE_APP_AUTHENTICATE === 'false') {
    return false
  }

  if (!toRoute.matched.some((route) => route.meta && route.meta.dobleFactorRequired)) {
    return false
  }

  return !store.state.auth.isDoubleFactorActived
}
