import Base from './Base'
import Document from '../Document'

export default class TextsDocuments extends Base {
  component = 'TextsDocuments'

  constructor(data) {
    super(data)

    this.block1 = {
      title: data.documentsTitle1,
      documents: data.DocumentBlock1.map((documentData) => new Document(documentData)),
    }

    this.block2 = {
      title: data.documentsTitle2,
      documents: data.DocumentBlock2.map((documentData) => new Document(documentData)),
    }
  }
}
