import Base from './Base'
import ElementsColumsCard from '../ElementsColumsCard'
import Document from '../Document'

export default class TitleColumnsDocuments extends Base {
  component = 'TitleColumnsDocuments'

  constructor(data) {
    super(data)
    this.columns = new ElementsColumsCard(data.columnsTitle, data.Columns)
    this.documents = data.Documents.map((doc) => new Document(doc))
  }
}
