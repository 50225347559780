<template>
  <div id="app" class="flex flex-col items-stretch">
    <app-header />
    <app-menu v-if="$route.meta.showMenu !== false" />
    <app-breadcrumbs v-if="$route.meta.showBreadcrumb !== false" />
    <main class="flex-auto flex-shrink-0">
      <spinner v-if="requestInProgress" />
      <app-messages />
      <router-view />
    </main>
    <app-footer />
    <app-modal v-if="$modal.component" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '@/components/layout/AppHeader'
import AppMenu from '@/components/layout/AppMenu'
import AppBreadcrumbs from '@/components/layout/AppBreadcrumbs'
import AppMessages from '@/components/AppMessages'
import AppFooter from '@/components/layout/AppFooter'
import Spinner from '@/components/Spinner'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppMenu,
    AppBreadcrumbs,
    AppMessages,
    AppFooter,
    AppModal: () => import('@/components/AppModal'),
    Spinner,
  },
  computed: {
    ...mapState('portal', ['requestInProgress']),
  },
}
</script>

<style lang="scss">
// stylelint-disable-next-line selector-max-id
#app {
  height: 100%;
}
</style>
