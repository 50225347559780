import Card from '../Card'

export default class Base {
  constructor(data, fields = []) {
    fields = ['id', 'meta', 'title', 'children', ...fields]
    this.assignFields(fields, data)

    if (data.card_title) {
      this.card = new Card(data)
    }

    this.parentId = data.meta.parent ? data.meta.parent.id : undefined
    this.children = data.children.map((child) => child.id)
    this.menuText = data.menu_text
  }

  assignFields(fields, data) {
    for (const field of fields) {
      this[field] = data[field]
    }
  }
}
