import api from './apiService'
import { spanishPhone } from '../utils/phoneFormatter'

export default {
  getPersonalData() {
    return api.get('personal_data/')
  },

  validateSae(sae) {
    return api.get('ckeck_sae_key/', {
      sae,
    })
  },

  registerPhone(phone, access) {
    phone = spanishPhone(phone)

    return api.post('register_phone/', {
      phone,
      access,
    })
  },

  validatePhone(code, phone) {
    return api.put('validate_phone/', {
      smsKey: code,
      phone,
    })
  },

  registerEmail(email) {
    return api.post('register_mail/', {
      mail: email,
    })
  },

  validateEmail(code, mail) {
    return api.put('validate_mail/', {
      mailKey: code,
      mail,
    })
  },

  checkIfRetired() {
    return api.get('check_if_senior/')
  },

  getSpecialAgreementSenior() {
    return api.get('special_agreement/').then((response) => {
      return {
        amount: (response.motivo !== '01' && Number(response.importe)) || 0,
        reasonCode: response.motivo,
        estimated: Number(response.importe) || 0,
        currentExerciseDate: new Date(response.ejercicio),
        updateDate: response.fecha ? new Date(response.fecha) : undefined,
        updateBeforeDate: new Date(response.fecha_fin_actualizacion),
        minAmount: Number(response.importe_min),
        maxAmount: Number(response.importe_max),
        closingStartDate: new Date(response.fecha_inicio_cierre),
        closingEndDate: new Date(response.fecha_fin_cierre),
      }
    })
  },

  postSpecialAgreementSenior(amount) {
    return api.post('special_agreement/', {
      amount,
    })
  },

  getPersonalDataForm() {
    return api.get('personal_data_form/')
  },

  async savePersonalData(data) {
    const response = await api.put('update_personal_data', data)
    return response
  },

  getCivilStates() {
    return api.get('civil_state/')
  },

  getNations() {
    return api.get('nations/')
  },

  getGenres() {
    return api.get('genres/')
  },

  getHandicapExtended() {
    return api.get('handicap_extended/')
  },

  getRelationships() {
    return api.get('relationships/')
  },

  getResidentCategory() {
    return api.get('resident_category/')
  },

  getWorkCategory() {
    return api.get('work_category/')
  },

  getPensionRelationship() {
    return api.get('pension_relationship/')
  },

  postUnemploymentBenefits(requestReason, files) {
    return api.post('unemployment_benefit/', {
      requestReason,
      files,
    })
  },

  getUnemploymentBenefits() {
    return api.get('unemployment_benefit/')
  },

  postSpecialAgreementDc(data) {
    return api.post('special_agreement_dc/', data)
  },

  getSpecialAgreementDc() {
    return api.get('special_agreement_dc/').then((response) => {
      if (response.retorno.estado === 'OK') {
        const requests = response.convenioEspecialVO.map((request) => {
          let file
          if (request.ficherosVO) {
            file = {
              name: request.ficherosVO[0].nombreFichero,
              data: request.ficherosVO[0].ficheroB64,
            }
          }

          return {
            isPending: request.solicitudEnTramite,
            fromDate: request.fechaInicio,
            untilDate: request.fechaFin,
            amount: request.importe,
            file,
          }
        })
        return {
          maxAmount: response.importeMax,
          minAmount: response.importeMin,
          requests,
        }
      }
    })
  },
}
