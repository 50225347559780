<template>
  <div class="spinner w-full flex justify-center items-center fixed inset-0 z-50">
    <img class="image-animation" src="/imgs/spinner.gif" alt="spinner" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>

<style lang="scss" scoped>
.spinner {
  background-color: rgba(255, 255, 255, 0.7);

  .image-animation {
    width: 50px;
    height: 50px;
  }
}
</style>
