import { ChildrenBuilder } from '@/shared/utils/childrenBuilder'

const LANDINGS = {
  myData: 'getUserData',
  benefits: 'getBenefits',
  socialWelfare: 'getSocialWelfare',
  otherProducts: 'getOtherProducts',
}

export default {
  getUserData: (state) => {
    const userData = { ...state.pages[state.landingsId.myData] }
    if (Object.entries(userData).length === 0) {
      return
    }

    const childrenBuilder = new ChildrenBuilder(state.pages)
    userData.children = childrenBuilder.getDataChildrens(userData)
    return userData
  },
  getSocialWelfare: (state) => {
    const socialWelfare = { ...state.pages[state.landingsId.socialWelfare] }
    if (Object.entries(socialWelfare).length === 0) {
      return
    }

    const childrenBuilder = new ChildrenBuilder(state.pages)
    socialWelfare.children = childrenBuilder.getDataChildrens(socialWelfare)
    return socialWelfare
  },
  getCarousel: (state) => {
    return state.pages[state.carouselId]
  },
  getBenefits: (state) => {
    const benefits = { ...state.pages[state.landingsId.benefits] }
    if (Object.entries(benefits).length === 0) {
      return
    }

    const childrenBuilder = new ChildrenBuilder(state.pages)
    benefits.children = childrenBuilder.getDataChildrens(benefits)
    return benefits
  },
  getOtherProducts: (state) => {
    const otherProducts = { ...state.pages[state.landingsId.otherProducts] }
    if (Object.entries(otherProducts).length === 0) {
      return
    }

    const childrenBuilder = new ChildrenBuilder(state.pages)
    otherProducts.children = childrenBuilder.getDataChildrens(otherProducts)
    return otherProducts
  },
  getLandingData: (state, getters) => (landingId) => {
    const landing = state.pages[landingId]
    if (!landing) return

    const typeLanding = landing.landing
    const nameGetterLanding = LANDINGS[typeLanding]

    return getters[nameGetterLanding]
  },
}
