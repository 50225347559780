class ContactDataAssembler {
  assemble(data, nationalities) {
    if (!data || !nationalities) {
      return
    }
    return {
      postalAddress: data.domicilioPostal,
      municipality: data.municipioPostal,
      postalCodeCountry: this.nationalityOptionBuilder(data.codigoPaisPostal, nationalities),
      postalCodeCountryOptions: this.nationalitiesOptionsBuilder(nationalities),
      postalCode: data.cpPostal,
      fiscalAddress: data.domicilioFiscal,
      fiscalMunicipality: data.municipioFiscal,
      codeFiscalCountry: this.nationalityOptionBuilder(data.codigoPaisFiscal, nationalities),
      codeFiscalCountryOptions: this.nationalitiesOptionsBuilder(nationalities),
      fiscalPostalCode: data.cpFiscal,
      email: data.email,
      phone: data.telefono_fijo,
      movilePhone: data.telefono_movil,
      status: data.estado,
    }
  }

  assembleToCMS(data, fileInformation) {
    return {
      domicilioPostal: data.postalAddress.toUpperCase(),
      municipioPostal: data.municipality.toUpperCase(),
      codigoPaisPostal: data.postalCodeCountry,
      cpPostal: data.postalCode,
      domicilioFiscal: data.fiscalAddress.toUpperCase(),
      municipioFiscal: data.fiscalMunicipality.toUpperCase(),
      codigoPaisFiscal: data.codeFiscalCountry,
      codigoPaisFiscal_old: data.codeFiscalCountryOld,
      cpFiscal: data.fiscalPostalCode,
      email: data.email.toUpperCase(),
      telefono_fijo: data.phone,
      telefono_movil: data.movilePhone,
      nombreFichero: fileInformation.files !== null ? fileInformation.files.name : null,
      fichero: fileInformation.files !== null ? fileInformation.files.base64.toString() : null,
    }
  }
  nationalityOptionBuilder(option, nationalities) {
    if (!option || !nationalities) {
      return
    }

    const preSelectedNationalityRaw = this.nationalityFinder(option, nationalities)

    return {
      value: preSelectedNationalityRaw.codigo,
      title: preSelectedNationalityRaw.literalLargo,
    }
  }

  nationalityFinder(nationality, nationalities) {
    return nationalities.find((nation) => nation.codigo === nationality)
  }

  nationalitiesOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return {
        value: option.codigo,
        title: option.literalLargo,
      }
    })
  }
}

export default new ContactDataAssembler()
