export default {
  add(state, message) {
    state.messages.push(message)
  },

  remove(state, index) {
    state.messages.splice(index, 1)
  },

  empty(state) {
    state.messages = []
  },
}
