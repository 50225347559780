import { mapState, mapGetters } from 'vuex'
import { viewerRoute, NavigationRoutes } from '@/shared/constants/navigation.routes'

export default {
  computed: {
    ...mapGetters('auth', ['authenticated']),
    ...mapState('portal', ['homeId']),
    homeUrl() {
      return this.authenticated && this.homeId
        ? viewerRoute + this.homeId
        : NavigationRoutes.general.auth.path
    },
  },
  methods: {
    goHome() {
      this.$router.push('/viewer/' + this.homeId)
    },
  },
}
