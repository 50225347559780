class FamilyDataCMSAssembler {
  assembleToCMS(family, fileInformation) {
    return {
      familiaresSenior: family.familiaresSenior,
      nombreFichero: fileInformation.files.name,
      fichero: fileInformation.files.base64.toString(),
    }
  }

  assemble(data) {
    if (!data) {
      return
    }

    return {
      familiaresSenior: data.map((familiar) => this.assembleFamiliar(familiar)),
    }
  }

  assembleFamiliar(familiar) {
    return {
      numero_familiar: this.assembleFamiliarNumber(familiar.familiarNumber),
      parentesco: familiar.relationship.value,
      nombre: familiar.name.toUpperCase(),
      primer_apellido: familiar.firstSurname.toUpperCase(),
      segundo_apellido: familiar.secondSurname.toUpperCase(),
      sexo: this.getGenreValue(familiar.genre),
      fecha_nacimiento: this.getDateAsString(familiar.birthDate, null),
      fecha_inicio_relacion: this.getDateAsString(familiar.relationshipStartDate, null),
      fecha_fallecimiento: this.getDateAsString(familiar.deathDate, familiar.action),
      estado_civil: familiar.civilState.value,
      trabaja: familiar.isWorking ? familiar.isWorking.value : null,
      minusvalia: familiar.handicap.extended.percentage,
      computa: familiar.isComputing.value,
      dni: this.addSlashToIdCard(familiar.idCard).toUpperCase(),
      accion: familiar.action,
    }
  }

  getGenreValue(genres) {
    if (!genres) {
      return
    }

    const selectedGenre = genres.find((genre) => genre.model === true)
    return selectedGenre.value
  }

  getDateAsString(data, action) {
    const dateParameter = data.dateControl ? data.dateControl : data.date
    let date = new Date(dateParameter)
    if (!data) {
      return
    }

    if (
      (data.isAlive === true && action === '5') ||
      (data.isAlive === true && action === '1') ||
      (data.isAlive === true && action === '')
    ) {
      return '0001-01-01'
    }

    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    let isBlank = false

    const response = `${year}-${month + 1}-${day}`

    if (action === '3') {
      const date = new Date()
      const today = date.getDate()
      isBlank = !!(today === day)
    }

    return isBlank ? '' : response
  }

  addSlashToIdCard(idCard) {
    if (!idCard || idCard === '') {
      return idCard
    }

    const idCardChar = idCard[idCard.length - 1]
    idCard = idCard.replace(idCardChar, '-')
    return idCard + idCardChar
  }

  assembleFamiliarNumber(familiarNumber) {
    if (!familiarNumber) {
      return null
    }

    const temporaryIdFlag = 'temporaryId'

    return familiarNumber.includes(temporaryIdFlag) ? null : familiarNumber
  }
}

export default new FamilyDataCMSAssembler()
