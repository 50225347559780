export default function getInitialState() {
  return {
    authId: undefined,
    homeId: undefined,
    secondaryPageId: undefined,
    carouselId: undefined,
    landingsId: {},
    mainMenu: {},
    headerMenu: {},
    pages: {},
    requestInProgress: false,
  }
}
