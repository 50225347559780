const apiPath = '/api/v2'
const loginPath = '/login/login-saml'
export const seniorBaseURL = process.env.VUE_APP_SENIOR_API + apiPath
export const dcBaseURL = process.env.VUE_APP_DC_API + apiPath
export const seniorLoginUrl = seniorBaseURL + loginPath
export const dcLoginUrl = dcBaseURL + loginPath
export const pagesPath = '/pages'
export const documentaryManagerFiles = '/employees/dms_receipts'
export const documentaryManagerDetailFile = {
  asset: '/employees/money_assets',
  loan: '/employees/money_loan_receipts',
  annualTaxInformation: '/employees/annual_money_info',
  loanTaxInformation: '/employees/money_loans_info',
  loansCancellationCertificates: '/employees/loan_cancellation_certificates',
}
export const personalDataForm = {
  personalData: '/employees/personal_data_form',
}

export const civilStates = {
  civilState: '/employees/civil_state',
}

export const nationalities = {
  nationality: 'employees/nations',
}

export const handicapExtended = {
  handicapExtended: 'employees/handicap_extended',
}

export const relationships = {
  relationship: 'employees/relationships',
}
