import Base from './Base'
import Image from '../Image'

export default class Carousel extends Base {
  component = 'Carousel'

  constructor(data) {
    super(data)
    this.slides = []

    if (data.carousel) {
      for (const slideData of data.carousel) {
        this.addSlide(slideData)
      }
    }
  }

  addSlide(data) {
    const slide = {}

    slide.template = 'CarouselSlideTemplate' + data.carouselTemplates
    slide.title = data.card_title
    slide.subtitle = data.subtitle
    slide.text = data.card_text
    slide.image = data.card_image ? new Image(data.card_image) : undefined
    slide.linkText = data.linkText
    slide.linkUrl = data.external_url_card

    this.slides.push(slide)
  }
}
