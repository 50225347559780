<template>
  <app-section class="app-footer py-83 bg-blue-800 internal-padding">
    <img class="h-32" src="/imgs/logo-bbva-white.svg" alt="Logo BBVA" />
    <div class="options py-38 text-grey-250 font-bbva font-medium text-13">
      <a :href="legalHref" class="option cursor-pointer mr-42" target="_blank">
        {{ $t('LAYOUT.FOOTER.LEGAL.LEGAL_ADVISE') }}
      </a>
      <a :href="termsHref" class="option cursor-pointer mr-42" target="_blank">
        {{ $t('LAYOUT.FOOTER.LEGAL.USAGE_TERMS') }}
      </a>
      <a :href="personalDataHref" class="option cursor-pointer mr-42" target="_blank">
        {{ $t('LAYOUT.FOOTER.LEGAL.PERSONAL_DATA') }}
      </a>
    </div>
    <div class="copyright text-12 text-grey-250">
      © Banco Bilbao Vizcaya Argentaria, S.A. {{ year }}
    </div>
  </app-section>
</template>

<script>
import { isDcUrl } from '@/shared/utils/isDcUrl'

export default {
  name: 'AppFooter',

  components: {
    AppSection: () => import('@/components/AppSection'),
  },

  data: () => ({
    year: new Date().getFullYear(),
  }),

  computed: {
    legalHref() {
      return isDcUrl() ? '/legal/dc-aviso-legal.pdf' : '/legal/senior-aviso-legal.pdf'
    },

    termsHref() {
      return isDcUrl() ? '/legal/dc-condiciones-de-uso.pdf' : '/legal/senior-condiciones-de-uso.pdf'
    },

    personalDataHref() {
      return isDcUrl() ? '/legal/dc-datos-personales.pdf' : '/legal/senior-datos-personales.pdf'
    },
  },
}
</script>
