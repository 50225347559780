import Base from './Base'
import Document from '../Document'
import Link from '../Link'

export default class TitleTextDocuments extends Base {
  component = 'TitleTextDocuments'

  constructor(data) {
    super(data, ['text'])

    this.documents = data.Documents.map((documentData) => new Document(documentData))
    this.links = data.Links.map((link) => new Link(link))
  }
}
