export default class PersonalDataState {
  nations = undefined
  civilStates = undefined
  genres = undefined
  handicapExtended = undefined
  relationships = undefined
  residentCategories = undefined
  workCategory = undefined
  pensionRelationship = undefined
  notAssembledPersonalData = undefined
  assembledPersonalData = undefined
  assembledContactData = undefined
  assembledFamilyData = undefined
  assembledPensionData = undefined
  assembledWithHoldingsData = undefined
  personalDataFormDataReadyToSendToCMSAssembler = undefined
  personalDataFormDataReadyToSendToCMS = undefined
  contactDataFormDataReadyToSendToCMSAssembler = undefined
  contactDataFormDataReadyToSendToCMS = undefined
  familyDataFormDataReadyToSendToCMSAssembler = undefined
  familyDataFormDataReadyToSendToCMS = undefined
  pensionDataFormDataReadyToSendToCMSAssembler = undefined
  pensionDaraFormDataReadyToSendToCMS = undefined
  withHoldingFormDataReadyToSendToCMSAssembler = undefined
  sectionToUpdate = undefined
  status = undefined
}
