export default class DropdownOption {
  constructor(data) {
    this.id = data.id
    this.value = data.id
    this.meta = data.meta
    this.title = data.title_option
    this.question = data.question
    this.answer = data.request
    this.list = data.options
  }
}
