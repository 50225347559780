import getInitialState from './state'

export default {
  setPage(state, payload) {
    state.pages = {
      ...state.pages,
      [payload.id]: payload,
    }
  },
  setAuthId(state, id) {
    state.authId = id
  },

  setSecondaryPageId(state, id) {
    state.secondaryPageId = id
  },

  setHomeId(state, id) {
    state.homeId = id
  },
  setCarouselId(state, id) {
    state.carouselId = id
  },
  addToMainMenu(state, { id, text }) {
    state.mainMenu = {
      ...state.mainMenu,
      [text]: id,
    }
  },
  addToHeaderMenu(state, { url, text }) {
    state.headerMenu = {
      ...state.headerMenu,
      [text]: url,
    }
  },
  setLandingId(state, { id, landing }) {
    state.landingsId = {
      ...state.landingsId,
      [landing]: id,
    }
  },
  setRequestInProgressStatus(state, requestStatus) {
    state.requestInProgress = requestStatus
  },
  resetState(state) {
    Object.assign(state, getInitialState())
  },
}
