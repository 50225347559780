import Vue from 'vue'
import router from '@/router'
import http from '@/shared/http'

import { NavigationRoutes } from '@/shared/constants/navigation.routes'

export default [
  {
    ...NavigationRoutes.errors.unauthorized,
    component: () => import(/* webpackChunkName: 'errors' */ '@/views/errors/Unauthorized.vue'),
    meta: {
      excludeAuth: true,
      showHeaderLinks: false,
      showMenu: false,
      showBreadcrumb: false,
    },
  },
  {
    ...NavigationRoutes.errors.notFound,
    component: () => import(/* webpackChunkName: 'errors' */ '@/views/errors/NotFound.vue'),
    meta: {
      excludeAuth: true,
      showHeaderLinks: false,
      showMenu: false,
      showBreadcrumb: false,
    },
  },
  {
    ...NavigationRoutes.errors.serverDown,
    component: () => import(/* webpackChunkName: 'errors' */ '@/views/errors/Error.vue'),
    meta: {
      excludeAuth: true,
      showHeaderLinks: false,
      showMenu: false,
      showBreadcrumb: false,
    },
  },
  {
    ...NavigationRoutes.errors.maintenance,
    component: () => import(/* webpackChunkName: 'errors' */ '@/views/errors/Maintenance.vue'),
    meta: {
      excludeAuth: true,
      showHeaderLinks: false,
      showMenu: false,
      showBreadcrumb: false,
    },
  },
]

const redirections = {
  403: NavigationRoutes.errors.unauthorized,
  500: NavigationRoutes.errors.serverDown,
  503: NavigationRoutes.errors.maintenance,
}

http.interceptors.response.use(undefined, (error) => {
  if (error && error.response) {
    const { status: code } = error.response

    if (code in redirections) {
      Vue.prototype.$modal.close()
      router.push(redirections[code])
    }
  }

  throw error
})
