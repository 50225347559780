class WithHoldingDataAssembler {
  assemble(data) {
    if (!data) {
      return
    }

    return {
      percentageRetention: data.porcentajeRetencion,
      closingPayrollDate: this.assembleDate(data.fechaCierreNomina),
      employeeClosingPayrollDate: this.assembleDate(data.fechaUltimaNominaEmpleado),
    }
  }

  assembleToCMS(data, fileInformation) {
    if (!data || !fileInformation) {
      return
    }

    return {
      porcentajeRetencion: parseInt(data.withHolding),
    }
  }

  assembleDate(date) {
    if (!date) {
      return
    }

    const splittedDate = date.split('-')
    return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2])
  }
}

export default new WithHoldingDataAssembler()
