import { pagesPath } from '../constants/api.routes'
import http from '../http'

export default {
  async getPagesIdByType(type) {
    const response = await http.get(`${pagesPath}/`, {
      params: {
        type,
      },
    })

    return response.data.items.map((page) => page.id)
  },

  async getPageById(id) {
    const response = await http.get(`${pagesPath}/${id}/`)

    return response.data
  },
}
