import Base from './Base'
import ElementsColumsCard from '../ElementsColumsCard'
import Document from '../Document'

export default class TextDocumentsColumns extends Base {
  component = 'TextDocumentsColumns'

  constructor(data) {
    super(data, ['text'])

    this.documents = data.Documents.map((documentData) => new Document(documentData))
    this.columns = new ElementsColumsCard(data.columnsTitle, data.Columns)
  }
}
