<template>
  <button type="button" :class="style" :disabled="disabled" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      const style = [
        'text-15',
        'font-medium',
        'leading-160',
        'text-center',
        'text-white',
        'border-none',
        'py-16',
        'px-32',
        'rounded-1',
      ]

      if (this.disabled) {
        style.push('cursor-not-allowed')
      }

      return style
    },
  },
}
</script>
