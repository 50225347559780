export default {
  add({ state, commit }, message) {
    if (!message) {
      return
    }

    if (typeof message === 'string') {
      message = {
        text: message,
      }
    }

    if (!message.text) {
      return
    }

    if (!message.type || !state.types.includes(message.type)) {
      message.type = 'info'
    }

    commit('add', message)
  },

  remove({ state, commit }, index) {
    if (typeof index !== 'number') {
      index = state.messages.findIndex((m) => m.text === (index.text || index))
    }

    if (index === undefined || !state.messages[index]) {
      return
    }

    commit('remove', index)
  },

  empty({ commit }) {
    commit('empty')
  },
}
