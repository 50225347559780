import Image from './Image'

export default class ContactCard {
  constructor(data) {
    this.title = data.title
    this.image = data.image ? new Image(data.image) : undefined
    this.text = data.text
    this.contact = data.contact
  }
}
