import { NavigationRoutes } from '@/shared/constants/navigation.routes'

export default [
  {
    ...NavigationRoutes.general.default,
    redirect: '/auth',
  },
  {
    ...NavigationRoutes.general.viewer,
    component: () => import('@/components/TemplateViewer.vue'),
  },
  {
    ...NavigationRoutes.general.documentaryManager,
    component: () => import('@/views/documentaryManager/DocumentaryManager.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
  {
    ...NavigationRoutes.general.specialAgreement,
    component: () => import('@/views/special-agreement/SpecialAgreement.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
  {
    ...NavigationRoutes.general.auth,
    component: () => import('@/views/auth/Auth.vue'),
    meta: {
      excludeAuth: true,
      showHeaderLinks: false,
      showMenu: false,
      showBreadcrumb: false,
    },
  },
  {
    ...NavigationRoutes.general.myProfile,
    component: () => import('@/views/my-profile/MyProfile.vue'),
  },
  {
    ...NavigationRoutes.general.personalData,
    component: () => import('@/views/personal-data/PersonalData.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
  {
    ...NavigationRoutes.general.unemploymentBenefits,
    component: () => import('@/views/unemployment-benefits/UnemploymentBenefits.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
  {
    ...NavigationRoutes.general.autocertificados,
    component: () => import('@/views/autocertificados/Autocertificados.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
  {
    ...NavigationRoutes.general.loans,
    component: () => import('@/views/loans/Loans.vue'),
    meta: {
      dobleFactorRequired: true,
    },
  },
]
