export class ChildrenBuilder {
  constructor(pages) {
    this.pages = pages
  }

  getDataChildrens(data) {
    const formattedChildrens = new Array()
    for (const childrenId of data.children) {
      const childrenData = this.pages[childrenId]
      if (childrenData) {
        formattedChildrens.push(childrenData)
      }
    }

    return formattedChildrens
  }
}
