class PersonalDataCMSAssembler {
  assemble(data, fileInformation) {
    if (!data || !fileInformation) {
      return
    }

    return {
      nombre: data.name.toUpperCase(),
      primerApellido: data.firstSurname.toUpperCase(),
      segundoApellido: data.secondSurname.toUpperCase(),
      dni: this.addSlashToIdCard(data.idCard).toUpperCase(),
      nacionalidad: data.nationality.value,
      genero: this.setGenre(data.genre),
      fechaNacimiento: this.setDate(data.birthDate),
      residente: data.fiscalResidence.value,
      residente_old: data.fiscalResidenceOld,
      nss: data.socialSecurity,
      minusvalia: this.setHandicap(data),
      catego_minusvalia: this.setHasHandicapHelp(data.handicapExtended),
      estadoCivil: data.civilState.value,
      fechaEstadoCivil: this.setDate(data.civilStateDate),
      nombreFichero: fileInformation.files.name,
      fichero: fileInformation.files.base64.toString(),
    }
  }

  addSlashToIdCard(idCard) {
    const idCardChar = idCard[idCard.length - 1]
    idCard = idCard.replace(idCardChar, '-')
    return idCard + idCardChar
  }

  setGenre(genre) {
    const selectedGenre = genre.find((option) => option.model === true)

    return selectedGenre.value
  }

  setDate(birthDate) {
    const year = birthDate.getFullYear()
    const month = birthDate.getMonth() + 1
    const day = birthDate.getDate()

    return `${year}-${month}-${day}`
  }

  setHandicap(data) {
    const hasHandicap = data.handicap[0].model === true
    return hasHandicap ? parseInt(data.percentage) : 0
  }

  setHasHandicapHelp(info) {
    const selectedHandicapHelp = info.find((option) => option.model === true)

    return selectedHandicapHelp.value
  }
}

export default new PersonalDataCMSAssembler()
