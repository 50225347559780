import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import errors from './errors'
import general from './general'
import * as guards from './guards'
import PhoneValidation from '@/components/modals/PhoneValidation'
import employeeService from '@/shared/services/employeeService'

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...errors,
    ...general,
    {
      path: '*',
      redirect: 'errors/not-found',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('messages/empty')

  if (guards.mustLogin(to)) {
    try {
      next(false)
    } catch (e) {} // eslint-disable-line

    store.dispatch('auth/login')
    return
  }

  if (guards.mustDoubleFactor(to)) {
    try {
      next(false)
    } catch (e) {} // eslint-disable-line

    if (!store.state.auth.user) {
      await store.dispatch('auth/checkAuthenticated')
    }

    employeeService.registerPhone(store.state.auth.user.validationPhone, 'GD')

    Vue.prototype.$modal
      .open(PhoneValidation, {
        maskedPhone: store.getters['auth/maskedPhone'],
        translationKeys: {
          title: 'MODALS.DOUBLE_FACTOR.TITLE',
        },
      })
      .then((response) => {
        if (response.validated) {
          router.push(to)
        }
      })

    return
  }

  next()
})

export default router
