class FamilyDataAssembler {
  assemble(seniorData, data, genres, civilStates, relationships, workCategories) {
    if (!data || !seniorData || !genres || !civilStates || !relationships || !workCategories) {
      return
    }

    return {
      userBirthDate: this.assembleBirthDate(seniorData.fechaNacimiento),
      userCivilState: seniorData.estadoCivil,
      family: this.assembleFamily(
        data.familiaresSenior,
        genres,
        civilStates,
        relationships,
        workCategories
      ),
      status: data.estado,
      genreOptions: this.assembleGenre(1, genres),
      civilStateOptions: this.civilStateOptionsBuilder(civilStates),
      relationshipOptions: this.relationshipsOptionsBuilder(relationships),
      isComputingOptions: this.isComputingOptionsBuilder(),
      workCategoryOptions: this.workCategoryOptionsBuilder(workCategories),
    }
  }

  assembleFamily(family, genres, civilStates, relationships, workCategories) {
    if (!family) {
      return []
    }

    return family.map((familiar) =>
      this.assembleSingleFamiliar(familiar, genres, civilStates, relationships, workCategories)
    )
  }

  assembleSingleFamiliar(familiar, genres, civilStates, relationships, workCategories) {
    const isComputingOptions = this.isComputingOptionsBuilder()
    return {
      familiarNumber: familiar.numero_familiar,
      summaryRelationship: this.assembleRelationshipSummary(familiar.parentesco, relationships),
      relationship: this.relationshipOptionBuilder(familiar.parentesco, relationships),
      relationshipOptions: this.relationshipsOptionsBuilder(relationships),
      name: familiar.nombre,
      firstSurname: familiar.primer_apellido,
      secondSurname: familiar.segundo_apellido,
      summaryGenre: this.assembleSummaryGenre(familiar.sexo, genres),
      genre: this.assembleGenre(familiar.sexo, genres),
      birthDate: this.assembleBirthDate(familiar.fecha_nacimiento),
      relationshipStartDate: this.assembleRelationshipStartDate(familiar.fecha_inicio_relacion),
      deathDate: this.assembleDeathDate(familiar.fecha_fallecimiento),
      summaryCivilState: this.assembleSummaryCivilState(familiar.estado_civil, civilStates),
      civilState: this.civilSateOptionBuilder(familiar.estado_civil, civilStates),
      civilStateOptions: this.civilStateOptionsBuilder(civilStates),
      summaryIsWorking: this.assembleSummaryIsWorking(familiar.trabaja, workCategories),
      isWorking: this.workCategoryOptionBuilder(familiar.trabaja, workCategories),
      isWorkingOptions: this.workCategoryOptionsBuilder(workCategories),
      summaryHandicap: this.assembleSummaryHandicap(familiar.minusvalia),
      handicap: this.assembleHandicap(familiar.minusvalia),
      isComputing: familiar.computa
        ? this.isComputingOptionBuilder(familiar.computa, isComputingOptions)
        : this.isComputingOptionBuilder(isComputingOptions[0].value, isComputingOptions),
      isComputingOptions: isComputingOptions,
      idCard: this.assembleIdCard(familiar.dni),
      action: familiar.accion ? familiar.accion : '',
    }
  }

  assembleSingleFamiliarFromForm(familiar) {
    return {
      familiarNumber: familiar.familiarNumber,
      summaryRelationship: familiar.relationship.title,
      relationship: familiar.relationship,
      relationshipOptions: familiar.relationshipOptions,
      name: familiar.name,
      firstSurname: familiar.firstSurname,
      secondSurname: familiar.secondSurname,
      summaryGenre: this.assembleSummaryGenreFromForm(familiar.genreOptions),
      genre: familiar.genreOptions,
      birthDate: this.assembleDateFromForm(familiar.birthDate),
      relationshipStartDate: this.assembleRelationshipStartDateFromForm(
        familiar.relationshipStartDate
      ),
      deathDate: this.assembleDeathDateFromForm(familiar.deathDate),
      idCard: familiar.idCard,
      summaryCivilState: familiar.civilState.title,
      civilState: familiar.civilState,
      civilStateOptions: familiar.civilStateOptions,
      summaryIsWorking: familiar.isWorking.title,
      isWorking: familiar.isWorking,
      isWorkingOptions: familiar.isWorkingOptions,
      handicap: familiar.handicap,
      summaryHandicap: this.assembleSummaryHandicapFromForm(familiar.handicap),
      isComputing: familiar.isComputing,
      isComputingOptions: familiar.isComputingOptions,
      action: familiar.accion ? familiar.accion : '',
    }
  }

  assembleSummaryHandicapFromForm(handicap) {
    const hasPercetage = handicap.extended.percentage
    return !!hasPercetage && hasPercetage > 0 ? handicap.extended.percentage : 0
  }

  assembleDateFromForm(date) {
    return {
      date: date,
      dateControl: date,
      disabledDates: new Date(),
    }
  }
  assembleRelationshipStartDateFromForm(date) {
    return {
      date: date,
      dateControl: date,
      disabledDates: new Date(),
    }
  }

  assembleDeathDateFromForm(date) {
    return {
      date: date,
      dateControl: date,
      isAlive: this.setIsAliveFromForm(date),
      disabledDates: new Date(),
    }
  }

  setIsAliveFromForm(date) {
    const IS_EMPTY_FIELD_REFERENCE_VALUE = '2999-12-31'
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return IS_EMPTY_FIELD_REFERENCE_VALUE === `${year}-${month + 1}-${day}`
  }

  isComputingOptionsBuilder() {
    return [
      {
        value: ' ',
        title: 'No aplica',
      },
      {
        value: '0',
        title: '0%',
      },
      {
        value: '5',
        title: '50%',
      },
      {
        value: '1',
        title: '100%',
      },
    ]
  }

  assembleBirthDate(birthDate) {
    return {
      date: this.assembleDate(birthDate),
      disabledDates: this.buildDisabledDates(),
    }
  }
  assembleRelationshipStartDate(relationshipStartDate) {
    return {
      date: this.assembleDate(relationshipStartDate),
      disabledDates: this.buildDisabledDates(),
    }
  }

  assembleDeathDate(deathDate) {
    return {
      date: this.assembleDate(deathDate),
      isAlive: this.setIsAlive(deathDate),
      disabledDates: this.buildDisabledDates(),
    }
  }

  assembleDate(date) {
    if (!date) {
      return
    }

    if (date === '0001-01-01') {
      date = '2999-12-31'
    }

    const splittedDate = date.split('-')
    return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2])
  }

  setIsAlive(date) {
    if (!date) {
      return
    }

    return date === '2999-12-31' || date === '0001-01-01'
  }

  buildDisabledDates() {
    const date = new Date()
    const dateInMillisencons = date.getTime()
    const MILLISECONDS_IN_A_DAY = 86400000

    return new Date(dateInMillisencons + MILLISECONDS_IN_A_DAY)
  }

  assembleIdCard(idCard) {
    if (!idCard) {
      return
    }

    return (idCard = idCard.includes('-') ? idCard.replace('-', '') : idCard)
  }

  assembleSummaryHandicap(handicap) {
    if (!handicap) {
      return '0'
    }

    return `${handicap}`
  }

  assembleHandicap(handicap) {
    const DEFAULT_VALUE = 0
    if (handicap === null) {
      handicap = DEFAULT_VALUE
    }
    const hasHandicap = handicap > DEFAULT_VALUE
    return {
      values: [
        {
          name: 'Si',
          value: 'Y',
          model: hasHandicap,
        },
        {
          name: 'No',
          value: 'N',
          model: true,
        },
      ],
      extended: {
        percentage: hasHandicap ? handicap : DEFAULT_VALUE,
      },
    }
  }

  assembleSummaryIsWorking(option, isWorkingOptions) {
    if (!option || !isWorkingOptions) {
      return
    }
    const fullIsWorking = isWorkingOptions.find(
      (isWorkingOption) => isWorkingOption.codigo === option
    )

    return fullIsWorking.literalLargo
  }

  assembleSummaryGenre(option, genres) {
    if (!option || !genres) {
      return
    }
    const fullGenre = genres.find((genre) => genre.codigo === option)

    return fullGenre.literalLargo
  }

  assembleSummaryGenreFromForm(genres) {
    if (!genres) {
      return
    }

    const genre = genres.find((genre) => genre.model === true)

    return genre.name
  }

  assembleGenre(option, genres) {
    if (!option || !genres) {
      return
    }
    const maleInformation = this.getMaleInformation(genres)
    const femaleInformation = this.getFemaleInformation(genres)
    const isMale = option === '1'
    return [
      {
        name: maleInformation.literalLargo,
        value: maleInformation.codigo,
        model: isMale,
      },
      {
        name: femaleInformation.literalLargo,
        value: femaleInformation.codigo,
        model: !isMale,
      },
    ]
  }

  getMaleInformation(genres) {
    return genres.find((genreOption) => genreOption.codigo === '1')
  }

  getFemaleInformation(genres) {
    return genres.find((genreOption) => genreOption.codigo === '2')
  }

  assembleSummaryCivilState(option, civilStates) {
    if (!option || !civilStates) {
      return
    }

    const fullCivilState = civilStates.find((civilState) => civilState.codigo === option)

    return fullCivilState.literalLargo
  }

  civilStateOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return { value: option.codigo, title: option.literalLargo }
    })
  }

  civilSateOptionBuilder(option, civilStates) {
    if (!option || !civilStates) {
      return
    }

    const preSelectedCivilStateRaw = this.civilStateFinder(option, civilStates)
    return {
      value: preSelectedCivilStateRaw.codigo,
      title: preSelectedCivilStateRaw.literalLargo,
    }
  }

  civilStateFinder(civilState, civilStates) {
    return civilStates.find((civilStateOption) => civilStateOption.codigo === civilState)
  }

  isComputingOptionBuilder(option, isComputingOptions) {
    if (!option || !isComputingOptions) {
      return
    }

    const preSelectedIsComputing = this.isComputingFinder(option, isComputingOptions)
    return {
      value: preSelectedIsComputing.value,
      title: preSelectedIsComputing.title,
    }
  }

  isComputingFinder(isComputing, isComputingOptions) {
    return isComputingOptions.find((isComputingOption) => isComputingOption.value === isComputing)
  }

  workCategoryOptionBuilder(option, workCategoryOptions) {
    if (!option || !workCategoryOptions) {
      return
    }
    const preWorkCategory = this.workCategoryFinder(option, workCategoryOptions)
    return {
      value: preWorkCategory.codigo,
      title: preWorkCategory.literalLargo,
    }
  }

  workCategoryFinder(workCategory, workCategoryOptions) {
    return workCategoryOptions.find(
      (workCategoryOption) => workCategoryOption.codigo === workCategory
    )
  }

  workCategoryOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return {
        value: option.codigo,
        title: option.literalLargo,
      }
    })
  }

  assembleRelationshipSummary(option, relationships) {
    if (!option || !relationships) {
      return
    }

    const relationshipFullOption = relationships.find(
      (relationshipOption) => relationshipOption.codigo === option
    )
    return relationshipFullOption.literalLargo
  }

  relationshipsOptionsBuilder(options) {
    if (!options) {
      return
    }

    return options.map((option) => {
      return {
        value: option.codigo,
        title: option.literalLargo,
      }
    })
  }

  relationshipOptionBuilder(option, relationships) {
    if (!option || !relationships) {
      return
    }

    const preSelectedRelationshipRaw = this.relationshipFinder(option, relationships)

    return {
      value: preSelectedRelationshipRaw.codigo,
      title: preSelectedRelationshipRaw.literalLargo,
    }
  }

  relationshipFinder(relationship, relationships) {
    return relationships.find((relationshipOption) => relationshipOption.codigo === relationship)
  }

  assembleEmptyFamiliar(relationships, genres, civilStates, workCategories, isComputingOptions) {
    return {
      familiarNumber: this.createFamiliarNumber(),
      relationship: relationships[0],
      relationshipOptions: relationships,
      name: '',
      firstSurname: '',
      secondSurname: '',
      genre: genres,
      birthDate: this.assembleEmptyFamilarDate(),
      relationshipStartDate: this.assembleEmptyFamilarRelationshipStartDate(),
      deathDate: this.assembleEmptyFamilarDeathDate(),
      civilState: civilStates[0],
      civilStateOptions: civilStates,
      isWorking: workCategories[0],
      isWorkingOptions: workCategories,
      handicap: this.assembleHandicap(null),
      isComputing: isComputingOptions[0],
      isComputingOptions: isComputingOptions,
      idCard: '',
      action: '3',
    }
  }

  assembleEmptyFamilarDate() {
    return {
      date: new Date(),
      disabledDates: new Date(),
    }
  }

  assembleEmptyFamilarRelationshipStartDate() {
    return {
      date: new Date(),
      disabledDates: new Date(),
    }
  }

  assembleEmptyFamilarDeathDate() {
    return {
      date: new Date(),
      isAlive: true,
      disabledDates: new Date(),
    }
  }

  createFamiliarNumber() {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < 52; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return `temporaryId-${result}`
  }
}

export default new FamilyDataAssembler()
