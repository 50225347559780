import Base from './Base'
import Image from '../Image'

export default class Auth extends Base {
  component = 'Auth'

  constructor(data) {
    super(data, ['header', 'text', 'titleContact', 'phone', 'mail'])
    this.image = data.image ? new Image(data.image) : undefined
  }
}
